import Api from "src/helpers/request";

export const getUsersEndpoint = "/users?pagination=false";
export const addUserEndpoint = "/users";
export const getUsersbyIdEndpoint = "/users";
export const editUserEndpoint = "/users";
export const verifyUserEndPoint = "/verify-user";
export const setPasswordEndPoint = "/set-password";
export const reverifyUserEndpoint = "/reverify-user";
export const resetPasswordEndpoint = "/reset-password";
export const currentUserEndPoint = "/me";
export const activeUserEndPpoint = "/users";
export const changePasswordEndpoint = "change-password";
export const getPocVoucher = "poc-vouchers";

export const getUsers = () =>
  Api.call({ url: getUsersEndpoint, method: "get" }, { showLoader: true });


export const addUser = (data) =>
  Api.call(
    { url: addUserEndpoint, method: "post", data },
    { showLoader: true }
  );
export const getUsersbyId = (uid) =>
  Api.call(
    { url: `${getUsersbyIdEndpoint}/${uid}`, method: "get" },
    { showLoader: true });


export const getPocVoucherData = (pocId) =>
  Api.call(
    { url: `${getPocVoucher}?poc_assigned=${pocId}`, method: "get" },
    { showLoader: true });

export const editUser = (data, userId) =>
  Api.call(
    { url: `${editUserEndpoint}/${userId}`, method: "put", data },
    { showLoader: true }
  );

export const verifyUser = (uid, token) =>
  Api.call(
    { url: `${verifyUserEndPoint}/${uid}/${token}`, method: "get" },
    {
      showErrorNotification: false,
      noAuthhHeader: true,
      showLoader: true,
    }
  );

export const setPassword = (uid, token, data) =>
  Api.call(
    { url: `${setPasswordEndPoint}/${uid}/${token}`, method: "post", data },
    {
      showErrorNotification: false,
      noAuthhHeader: true,
      showLoader: true,
      successNotificationMessage: "You're all set, You can now login.",
    }
  );

export const reverifyUser = (email) =>
  Api.call(
    { url: `${reverifyUserEndpoint}/${email}`, method: "get" },
    {
      showLoader: true,
      successNotificationMessage: "Activation Email Resent Successfully",
    }
  );

export const resetPassword = (email) =>
  Api.call(
    { url: `${resetPasswordEndpoint}/${email}`, method: "get" },
    {
      showLoader: true,
      noAuthhHeader: true,
      showErrorNotification: false,
      successNotificationMessage: "Reset Password Email Sent Successfully",
    }
  );

export const getLoggedInUser = () =>
  Api.call({ url: currentUserEndPoint, method: "get" }, { showLoader: true });

// active / deactivate user
export const toggleUserStatus = (data, userId) =>
  Api.call(
    { url: `${activeUserEndPpoint}/${userId}`, method: "patch", data },
    { showLoader: true }
  );

export const changePassword = (data) =>
  Api.call(
    { url: `${changePasswordEndpoint}`, method: "patch", data },
    {
      showLoader: true,
      showErrorNotification: false,
      successNotificationMessage: "Password Changed Successfully",
    }
  );
