import React, { useState, useEffect } from "react";
import editIcon from "src/assets/images/editIcon.svg";
import deleteIcon from "src/assets/images/delete.svg";
import inactiveIcon from "src/assets/images/inactiveIcon.svg";
import visibleIcon from "src/assets/images/visibleIcon.svg";
import rightArrowIcon from "src/assets/images/right-arrow-color.svg";
import pocEditIcon from "src/assets/images/poc-edit.svg"
import pocDetailsIcon from "src/assets/images/redirect.svg";
import { pocsStatusMap } from "src/constants/pocs";
import { OverlayTrigger, Popover } from "react-bootstrap";
import CustomTooltip from "src/components/ui/CustomTooltip";
import { getDeployedConsoles } from "src/api/poc";
import { capitalizeFirstLetter } from "src/helpers/common";
import { useSelector } from "react-redux";
import { RootState } from "src/state/rootReducer";
import { userTypesMap } from "src/constants/users";
import { copyEmailToClipboard } from "src/helpers/pocs";

export const getPocsColumns = (
  toDetailsPoc
  , handleEditPoc
) => [
    {
      Header: "ID",
      accessor: "pocId",
      percentWidth: 5,
    },
    {
      Header: "Status",
      accessor: "status",
      percentWidth: 9,
    },
    {
      Header: "Start Date",
      accessor: "requestedStart",
      percentWidth: 8,
    },
    {
      Header: "Company",
      accessor: "company",
      percentWidth: 12,
    },
    {
      Header: "Opportunity ID",
      accessor: "oppId",
      percentWidth: 14,
      Cell: ({ row }) => {
        const redirectUrl = `https://purestorage.lightning.force.com/lightning/r/Opportunity/${row.original.oppId}/view`
        return (
          <div className="d-flex">
            <div className="pr-1">
              <a className="nav_link" href={redirectUrl} target="_blank">
                {row.original.oppId}
              </a>
            </div>
          </div>
        );
      }
    },
    {
      Header: "Account SE",
      accessor: "accountSe",
      percentWidth: 18,
      Cell: ({ row }) => {
        const Userlist = useSelector(
          (state: RootState) => (state.users.list).filter((user) => user.type == userTypesMap.corporate));
        const currentSe = Userlist.find(element => element.id === row.original.accountSe);

        const textObject = {
          toBeCopied: currentSe?.email,
          successMessage: 'Email copied to clipboard!',
          errorMessage: 'Failed to copy email:'
        }

        return (
          <div className="d-flex">
            <div title="" className="cursor-pointer" onClick={() => copyEmailToClipboard(textObject)}>
              <CustomTooltip text={currentSe?.email} placement="top">
                <a className="nav_link" href="#">
                  {currentSe?.name}
                </a>

                {/* <img
                  title=""
                  src={mailIcon}
                  alt="mailIcon"
                  height="18"
                  width="18"
                  onClick={() => copyEmailToClipboard(currentSe?.email)}
                // onContextMenu={handleRightClick}
                /> */}
              </CustomTooltip>
            </div>
            {/* <span>{currentSe?.name}</span> */}
            {/* Custom Context Menu */}
            {/* {contextMenu.email && (
              <div
                style={{
                  position: 'absolute',
                  top: contextMenu.top,
                  left: contextMenu.left,
                  backgroundColor: 'white',
                  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                  padding: '5px 10px',
                  borderRadius: '5px',
                  zIndex: 1000,
                }}
                onClick={closeContextMenu}
              >
                <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
                  <li
                    style={{ padding: '5px 0', cursor: 'pointer' }}
                    onClick={() => {
                      copyEmailToClipboard(contextMenu.email);
                      closeContextMenu();
                    }}
                  >
                    Copy Email Address
                  </li>
                </ul>
              </div>
            )} */}
          </div>
        );
      }
    },
    {
      Header: "POD",
      accessor: "podNumber",
      percentWidth: 6,
      Cell: ({ row }) => {
        return (
          <span>POD-{row.original.podNumber}</span>
        );
      },
    },
    {
      Header: "Consoles",
      percentWidth: 8,
      Cell: ({ row }) => {
        const [deployedConsoles, setDeployedConsoles] = useState(0);

        useEffect(() => {
          const fetchDeployedConsoles = async () => {
            const result = await getDeployedConsoles(row.original.pocId);
            setDeployedConsoles(result);
          };

          fetchDeployedConsoles();
        }, [row.original.pocId]);

        return (
          <span>{deployedConsoles !== 0 ? deployedConsoles : "--"}</span>
        );
      },
    },
    {
      Header: "Action",
      disableSortBy: true,
      accessor: (row) => {
        return (
          <div className="d-flex gap-1">
            <div title="" className="cursor-pointer">
              <CustomTooltip text="Edit POC" placement="top">
                <img
                  title=""
                  src={pocEditIcon}
                  alt=""
                  height="20"
                  width="20"
                  onClick={() => handleEditPoc(row.pocId)}
                />
              </CustomTooltip>
            </div>
            <div title="" className="cursor-pointer">
              <CustomTooltip text="To Details POCs" placement="top">
                <img
                  title=""
                  src={pocDetailsIcon}
                  alt="pocDetailsIcon"
                  height="20"
                  width="20"
                  onClick={() => toDetailsPoc(row.pocId)}
                />
              </CustomTooltip>
            </div>
          </div>
        );
      },
      percentWidth: 12,
    },
  ];

export const formatDate = (value) => {
  const date = new Date(value);
  const yyyy = date.getFullYear();
  let mm = (date.getMonth() + 1).toString(); // Months start at 0!
  let dd = date.getDate().toString();

  if (+dd < 10) dd = '0' + dd;
  if (+mm < 10) mm = '0' + mm;
  return `${mm}/${dd}/${yyyy}`;
}