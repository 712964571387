import React, { useState, useEffect } from "react";
import "./PocDetails.scss";
import { Link, useParams } from "react-router-dom";
import rightArrow from "src/assets/images/right-arrow.svg";
import editPocIcon from "src/assets/images/poc-edit.svg"
import addVmIcon from "src/assets/images/add-consoleVM.svg"
import { IPoc } from "src/state/pocs/reducer";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "src/state/rootReducer";
import { getPocs } from "src/state/pocs/actions";
import { getPocDetails } from "src/api/poc";
import { getPocVoucherData, getUsersbyId } from "src/api/users";
import { mapAdminPocs, mapPocDetails, mapPOCDetailsbyId } from "src/helpers/pocs";
import { formatDate } from "../PocsColumns";
import { ReactComponent as AddIcon } from "src/assets/images/add.svg";
import AddOrEditPoc from "src/components/AddOrEditPoc/AddOrEditPoc";
import UserVoucherModal from "src/components/user/UserVoucherModal";
import { pushNotification } from "src/components/ui/Notification";

import { PocVouchersColumns } from "./PocVoucherColumns";
import Table from "src/components/shared/Table";
import SearchBox from "src/components/shared/SearchBox";
import { Button } from "react-bootstrap";
import PureModal from "src/components/shared/PureModal";


const poc = {
    pocId: -1,
    createdOn: '',
    updatedOn: "",
    labProviderType: "",
    status: "",
    company: "",
    accountAe: "",
    oppId: "",
    requestedStart: null,
    actualStart: null,
    endDate: null,
    podNumber: -1,
    testPlan: null,
    theatre: "",
    stage: "",
    deployedBy: "",
    accountSe: "",
    activeDate: '',
    assignedUsers: [],
    assignedGroups: [],
    assignedCompanies: [],
    vcForUsers: [],
    vcForGroups: [],
    vcForCompanies: [],
    se_user: {
        id: "",
        email: "",
        name: "",
        company: null,
        type: "",
        userType: -1,
        role: "",
        userRole: -1,
        isActive: null,
        isInvited: null,
        labsLaunched: -1,
        lastLogin: "",
        status: ""
    }
}

// Define the interface for each nested object
interface InvitedBy {
    id: string;
    email: string;
    name: string;
}

interface PocAssigned {
    poc_id: number;
    status: string;
}

interface Voucher {
    name: string;
    invited_by: InvitedBy;
    claimed_by: string | null;
    claimed_on: string | null;
    voucher_url: string;
    email: string;
    code: string;
    poc_assigned: PocAssigned;
    status: string;
    created_on: string;
}

const PocDetails: React.FC<any> = () => {

    const [pocData, setPocData] = useState(poc);
    const [showAddorEditPocModal, setShowAddorEditPocModal] = useState(false)
    const [editType, setEditType] = useState(0)
    const pocs: any = useSelector((state: RootState) => state.pocs.list);
    const userList: Array<any> = useSelector((state: RootState) => (state.users.list));
    const [pocVoucherModal, setPocVoucherModal] = useState(false)
    const [pocVoucherDetailsModal, setPocVoucherDetailsModal] = useState(false)
    const [selectedVoucher, setSelectedVoucher] = useState<Voucher>();
    const [pocVoucherData, setPocVoucherData] = useState<Voucher[]>([]);

    const { pocId }: { pocId } = useParams();



    useEffect(() => {
        getPOCVoucherData();
        //on refersh Page
        if (pocs.length === 0) {
            const fetchPocbyId = async () => {
                let pocData = await getPocDetails(pocId);
                pocData = mapPOCDetailsbyId(pocData);
                pocData.se_user = await getUsersbyId(pocData.accountSe)
                pocData.activeDate = pocData.status_history.find(x => x.status == 'Active')?.effective_date
                setPocData(pocData);
            }
            fetchPocbyId()
        }
        //from poc listing page
        else {
            const fetchPocbyId = async () => {
                let pocData = pocs.find((item) => item.pocId == pocId);
                let userData = userList.find((item) => item.id == pocData?.accountSe) || await getUsersbyId(pocData.accountSe);
                pocData = { ...pocData, se_user: userData }
                pocData.activeDate = pocData.statusHistory.find(x => x.status == 'Active')?.effectiveDate
                setPocData(pocData)
            }
            fetchPocbyId()
        }
    }, [pocs])

    //get POC voucher Data by Id
    const getPOCVoucherData = async () => {
        const data = await getPocVoucherData(pocId);
        setPocVoucherData(data.results.sort((a, b) => {
            if (a.status === "claimed" && b.status === "un_claimed") {
                return -1; // "claimed" comes first
            }
        }));
    }
    //on More Details Click
    const handleVoucherDetails = (code) => {
        //console.log(code)
        const filteredVoucher = pocVoucherData.find(x => x.code == code);
        console.log('filteredVoucher', filteredVoucher)
        filteredVoucher && setSelectedVoucher(filteredVoucher)
        setPocVoucherDetailsModal(true);
    }
    const pocColumn = PocVouchersColumns(handleVoucherDetails);
    //on Edit Icon Click
    const handleEditPoc = (type) => {
        setEditType(type)
        setShowAddorEditPocModal(true)
    }

    //on hosted poc console
    const handlePocVoucher = () => {
        if (pocData.status == 'Canceled' || pocData.status === 'Incomplete' || pocData.status === 'Complete') {
            pushNotification({ type: "info", message: `Voucher cannot be generated as the POC status is ${pocData.status}` });
            return;
        }
        setPocVoucherModal(!pocVoucherModal)
    }

    //To generate Voucher Name
    const generateVoucherName = () => {
        const largestNumber = pocVoucherData && Math.max(
            ...pocVoucherData
                .map(item => {

                    const match = item.name.match(/VM-(\d+)/);
                    return match ? parseInt(match[1], 10) : -Infinity;
                })
        );
        let consoleId = largestNumber === -Infinity || !largestNumber ? 1 : largestNumber + 1;
        return `POC${pocId}-POD-${pocData.podNumber}-VM-${consoleId}`
    }
    const daysBetween = (dateString: string | null): number | string => {
        if (!dateString) {
            return ' - ';
        }
        // Parse the input date string into a Date object
        const inputDate: Date = new Date(dateString);

        // Get the current date
        const currentDate: Date = new Date();

        // Set the time of both dates to midnight to compare only the date part
        inputDate.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);

        // Calculate the difference in time (in milliseconds)
        const timeDiff: number = currentDate.getTime() - inputDate.getTime();

        // Convert the time difference from milliseconds to days
        const daysDiff: number = timeDiff / (1000 * 3600 * 24);

        // Return the absolute value of the difference (to handle past or future dates)
        return Math.abs(Math.floor(daysDiff));
    }

    return <>
        {showAddorEditPocModal && (
            <AddOrEditPoc
                isEditMode={true}
                type={editType}
                selectedPoc={pocData}
                setShowAddorEditPocModal={setShowAddorEditPocModal}
            />
        )}

        <div className="tabs-layout">
            <div className={`pocs-list-section-header mb-3`}>
                <b>
                    <Link className="group-user-header" to="/admin/pocs">
                        POCs
                    </Link>
                </b>
                <img
                    height="15"
                    width="15"
                    src={rightArrow}
                    alt="InfoIcon"
                    className="mr-1 ml-1"
                />
                <span className="h4">Hosted POC Details</span>
            </div>
            {/* <span className="mb-2 tabs-layout-header"><b> Hosted POC Details </b></span> */}
            <div className="details_container">
                <div className="card border-light px-4 py-3">
                    <div>
                        <InfoDetails lable="Opportunity Info" imageObj={{ img_url: editPocIcon, alt: 'Edit POC' }} handleClick={() => handleEditPoc(1)} >
                            <ul className="details_tab">
                                <InfoCapsules name="Company" value={pocData.company} />
                                <InfoCapsules name="Opportunity ID" value={pocData.oppId} />
                                <InfoCapsules name="Account SE" value={pocData.se_user.name} />
                                <InfoCapsules name="Account AE" value={pocData.accountAe} />
                                <InfoCapsules name="Theatre" value={pocData.theatre} />
                                <InfoCapsules name="Stage" value={pocData.stage} />
                            </ul>
                        </InfoDetails>
                    </div>
                    <div>
                        <InfoDetails lable="General Info" imageObj={{ img_url: editPocIcon, alt: 'Edit POC' }} handleClick={() => handleEditPoc(2)} >
                            <ul className="details_tab">
                                <InfoCapsules name="Created On" value={pocData.createdOn && formatDate(pocData.createdOn)} />
                                <InfoCapsules name="Status" value={pocData.status} />
                                <InfoCapsules name="Requested Start" value={pocData.requestedStart && formatDate(pocData.requestedStart)} />
                                <InfoCapsules name="Actual Start" value={pocData.activeDate && formatDate(pocData.activeDate)} />
                                <InfoCapsules name="Days Active" value={daysBetween(pocData.activeDate)} />
                                <InfoCapsules name="Date Closed" value="" />
                            </ul>
                        </InfoDetails>
                    </div>
                </div>
                <div className="card border-light px-4 py-3">
                    <div>
                        <InfoDetails lable="Hosted POC Console" imageObj={{ img_url: addVmIcon, alt: 'Add Console VM' }} handleClick={handlePocVoucher} ><div></div>
                            {pocVoucherModal && (
                                <UserVoucherModal setShowModal={setPocVoucherModal} voucherAdded={getPOCVoucherData} type='poc' voucherName={generateVoucherName} />
                            )}
                            {pocVoucherDetailsModal &&
                                <PureModal
                                    title='Voucher Details'
                                    showModal={true}
                                    closeModal={() => setPocVoucherDetailsModal(false)}
                                    // size='lg'
                                    // primaryBtnLabel="Generate"
                                    // primaryBtnAction={formik.handleSubmit}
                                    secondaryBtnLabel="Close"
                                    secondaryBtnAction={() => setPocVoucherDetailsModal(false)}>
                                    <VoucherDetails selectedVoucher={selectedVoucher} />

                                </PureModal>
                            }



                            <div className="poc_voucher_wrapper">
                                <Table columns={pocColumn} data={pocVoucherData} paginationOff={true} />
                            </div>
                        </InfoDetails>
                    </div>
                </div>
                <div className="card border-light px-4 py-3">
                    <ul className="details_tab">
                        <InfoDetails lable="Assigned Resources" isCapsule={true}> <InfoCapsules name="Assigned POD" value={pocData.podNumber} /></InfoDetails></ul></div>
                <div className="card border-light px-4 py-3"><InfoDetails lable="Notes/Comments"><div></div></InfoDetails></div>
                <div className="card border-light px-4 py-3"><InfoDetails lable="Attachments"><div></div></InfoDetails></div>
                <div className="card border-light px-4 py-3"><InfoDetails lable="Activities"><div></div></InfoDetails></div>
            </div>



            {/* <div className="pocVoucher_wrapper">
                <div className="d-flex flex-row justify-content-sm-between mb-3 poc_main">
                    <div className={`vouchers-section-header`}>
                        <h5 className="m-0">
                            <b>POC Vouchers</b>
                        </h5>
                    </div>
                    <div className="d-flex justify-content-end ">
                        {/* <div className="vouchers-section-search d-flex align-items-center">
                            <SearchBox
                                updateSearchQuery={handleSearchByChange}
                                placeholder="Search by voucher name or creator"
                            />
                        </div> 
                        <div className={` align-self-end ml-2 d-inline`}>
                            <Button
                                type="submit"
                                size="sm"
                                variant="primary"
                                className="vouchers-section-add"
                                onClick={handlePocVoucher}
                            >
                                <div className="d-flex align-items-center pl-2 pr-2">
                                    <AddIcon height="10" width="10" />
                                    <span className="ml-2"> Create POC Voucher </span>
                                </div>
                            </Button>
                        </div>
                    </div>
                </div>
                <Table columns={pocColumn} data={pocVoucherData} paginationOff={true} />

            </div> */}
        </div>

    </>

}
export default PocDetails;

const InfoDetails = ({ lable, children, ...props }) => {
    return <>
        <label className='card_header'>{lable} </label>
        {props.imageObj &&
            <img className="float-right mt-1 cursor-pointer" title="" src={props.imageObj.img_url} alt={props.imageObj.alt} height="18" width="18" onClick={props.handleClick}></img>
        }
        {children}
    </>
}
const InfoCapsules = ({ name, value }) => {
    return <>
        <li className="d-flex">
            <div>{name}</div>
            <div>{value}</div>
        </li>
    </>
}

const VoucherDetails = ({ selectedVoucher }) => {
    return <>
        <section className='basic_info'>
            <div className='card_sm'><div>Voucher Name</div><div>{selectedVoucher.name}</div></div>
            <div className='card_sm'><div>Status</div><div>{selectedVoucher.status === 'claimed' ? 'Claimed' : 'Un-Claimed'}</div></div>
        </section>

        <section className="user_data card_sm">
            <div className="first_col">
                <div><span>Invited By :</span><div className="value"> {selectedVoucher.invited_by.email} </div></div>
                <div><span>Created On :</span><div className="value"> {formatDate(selectedVoucher.created_on)}</div></div>
            </div>
            <div className="second_col">
                <div><span>Claimed By :</span><div className="value">{selectedVoucher.claimed_by?.email ? selectedVoucher.claimed_by.email : '--'}</div> </div>
                <div><span>Claimed On :</span> <div className="value">{selectedVoucher.claimed_on ? formatDate(selectedVoucher.claimed_on) : '--'}</div></div>
            </div>
        </section>

        <section className='basic_info voucher'>
            <div className='card_sm'><div>Voucher Code</div><div>{selectedVoucher.code}</div></div>
            <div className='card_sm'><div>Voucher URL</div><div><a href={selectedVoucher.voucher_url} target="blank">{selectedVoucher.voucher_url}</a></div></div>
        </section>
    </>
}