
import React from "react";
import { formatDate } from "../PocsColumns";
import { copyEmailToClipboard } from "src/helpers/pocs";
import CustomTooltip from "src/components/ui/CustomTooltip";
import infoIcon from "src/assets/images/info-icon-colored.svg"
export const PocVouchersColumns = (handleVoucherDetails) => [
    {
        Header: "Voucher Name",
        accessor: "name",
        percentWidth: 20,
        Cell: ({ row }) => {
            const textObject = {
                toBeCopied: row.original.voucher_url,
                successMessage: 'Voucher URL copied to clipboard!',
                errorMessage: 'Failed to copy Voucher URL:'
            }
            return (
                <div className="d-flex">
                    <div title="" className="cursor-pointer" onClick={() => copyEmailToClipboard(textObject)}>
                        <a className="nav_link" href="#">
                            {row.original.name}
                        </a>
                        {/* <CustomTooltip text='test' placement="top">
                         
                        </CustomTooltip> */}
                    </div>
                </div>
            );
        }
        //sortType: (rowA, rowB) => sortColumn(rowA, rowB, "name"),
    },
    // {
    //     Header: "Invited By",
    //     accessor: "invited_by.email",
    //     percentWidth: 20,
    // },
    // {
    //     Header: "Created Date",
    //     accessor: "created_on",
    //     percentWidth: 15,
    //     Cell: ({ value }) => {
    //         return value && formatDate(value);
    //     },
    // },
    {
        Header: "Claimed By",
        accessor: "claimed_by.email",
        percentWidth: 20,
        Cell: ({ value }) => {
            return value || "--";
        },
    },
    {
        Header: "Claimed Date",
        accessor: "claimed_on",
        percentWidth: 15,
        Cell: ({ value }) => {
            return (value && formatDate(value)) || "--";
        },
    },
    // {
    //     Header: "Status",
    //     accessor: "status",
    //     percentWidth: 16,
    //     Cell: ({ value }) => {
    //         return (
    //             <div
    //                 className={`d-flex justify-content-center status max-content status-${value == 'claimed' ? 'enabled' : ''
    //                     }`}
    //             >
    //                 {value == 'claimed' ? 'Claimed' : "--"}
    //             </div>
    //         );
    //     },
    //sortType: (rowA, rowB) => sortColumn(rowA, rowB, "email"),
    //},
    {
        Header: " ",
        disableSortBy: true,
        accessor: (row) => {
            return (
                <div className="d-flex gap-1">
                    <div title="" className="cursor-pointer">
                        <CustomTooltip text="More Details" placement="top">
                            <img
                                title=""
                                src={infoIcon}
                                alt=""
                                height="17"
                                width="17"
                                onClick={() => handleVoucherDetails(row.code)}
                            />
                        </CustomTooltip>
                    </div>

                </div>
            );
        },
        percentWidth: 5,
    },
];